import { thunk, action } from "easy-peasy"
import { fetchApi, fetchWithAuth1 } from "../../utils"
const sitesModel = {
  entities: {},
  auth1Connections: {},
  states: {
    auth1: {
      loading: false,
      init: false,
    },
    github: {
      loading: false,
      init: false,
    },
    "github-app": {
      loading: false,
      init: false,
    },
  },
  auth1Connection: null,
  githubConnection: null,
  "github-appConnection": null,
  fetchAppConnections: thunk(async (actions, { app }) => {
    actions.setState({
      id: app,
      loading: true,
    })
    try {
      const { data } = await fetchApi(`/apps/${app}/connections`)
      if (app === "auth1") {
        if (data.items.length > 0) {
          actions.setNativeConnection({
            ...data.items[0],
            app: "auth1",
          })
          actions.setState({
            id: app,
            loading: false,
            init: true,
          })
        } else {
          actions.setState({
            id: app,
            loading: false,
            init: true,
            empty: true,
          })
        }
      }
    } catch (error) {
      actions.setState({
        id: app,
        loading: false,
        error: error.message,
        init: true,
      })
    }
  }),
  fetchUserConnections: thunk(async actions => {
    const states = {
      auth1: {
        loading: true,
        init: false,
      },
      "github-app": {
        loading: true,
        init: false,
      },
      github: {
        loading: true,
        init: false,
      },
    }
    actions.setMultipleState(states)
    try {
      const { data } = await fetchApi(`/user/connections`)
      if (Array.isArray(data.items) && data.items.length > 0) {
        const storePayload = {}
        data.items.reverse().forEach(item => {
          storePayload[`${item.provider}Connection`] = item
        })

        const newStates = {
          auth1: {
            loading: false,
            init: true,
            empty: storePayload.auth1Connection ? false : true,
          },
          "github-app": {
            loading: false,
            init: true,
            empty: storePayload["github-appConnection"] ? false : true,
          },
          github: {
            loading: false,
            init: true,
            empty: storePayload.githubConnection ? false : true,
          },
        }
        actions.setStore(storePayload)

        actions.setMultipleState(newStates)
      } else {
        const newStates = {
          auth1: {
            loading: false,
            init: true,
            empty: true,
          },
          "github-app": {
            loading: false,
            init: true,
            empty: true,
          },
          github: {
            loading: false,
            init: true,
            empty: true,
          },
        }
        actions.setMultipleState(newStates)
      }
    } catch (error) {
      const newStates = {
        auth1: {
          loading: false,
          init: true,
          error: error.message,
        },
        "github-app": {
          loading: false,
          init: true,
          error: error.message,
        },
        github: {
          loading: false,
          init: true,
          error: error.message,
        },
      }
      actions.setMultipleState(newStates)
    }
  }),
  fetchAuth1Connections: thunk(async (actions, { app, token }) => {
    let stateKey = `auth1-${app}`
    actions.setState({
      id: stateKey,
      loading: true,
    })
    try {
      const { data } = await fetchWithAuth1(`/apps/${app}/connections`, {
        token,
      })
      if (data.items.length > 0) {
        actions.setAuth1Connections(data.items)
        actions.setState({
          id: stateKey,
          loading: false,
          init: true,
        })
      } else {
        actions.setState({
          id: stateKey,
          loading: false,
          init: true,
          empty: true,
        })
      }
    } catch (error) {
      actions.setState({
        id: stateKey,
        loading: false,
        error: error.message,
        init: true,
      })
    }
  }),
  setMultipleState: action((state, payload) => {
    state.states = {
      ...state.states,
      ...payload,
    }
  }),
  setState: action((state, payload) => {
    if (!state.states[payload.id]) {
      state.states[payload.id] = {}
    }
    state.states[payload.id] = { ...state.states[payload.id], ...payload }
  }),
  setAuth1Connections: action((state, payloads) => {
    payloads.forEach(payload => {
      state.entities[payload.connectionId] = payload
      if (!state.auth1Connections[payload.provider]) {
        state.auth1Connections[payload.provider] = []
      }
      state.auth1Connections[payload.provider].unshift(payload.connectionId)
    })
  }),
  setNativeConnection: action((state, payload) => {
    state[`${payload.app}Connection`] = payload
  }),
  setStore: action((state, payload) => {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  }),
}
export default sitesModel
