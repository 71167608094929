export const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN
export const auth0ClientId = process.env.GATSBY_AUTH0_CLIENTID
export const auth0RedirectURI = process.env.GATSBY_AUTH0_REDIRECT_URI
export const auth0Audience = process.env.GATSBY_AUTH0_AUDIENCE
export const auth0Scope = process.env.GATSBY_AUTH0_SCOPE
export const apiEndpoint = process.env.GATSBY_API_ENDPOINT
export const siteUrl = process.env.GATSBY_SITE_URL
export const auth1ApiEndpoint = process.env.GATSBY_AUTH1_API_ENDPOINT
export const githubApiEndpoint = `https://api.github.com`
export const siteName = process.env.GATSBY_SITE_NAME
export const githubHost = `https://github.com`
