import fetchFn from "cross-fetch"
import { toCamel } from "convert-keys"
import { auth0 } from "./auth"
import { apiEndpoint, auth1ApiEndpoint, githubApiEndpoint } from "../configs"
import { ResponseError, LoginRequired } from "../errors"
import { withSearch, getPathname } from "./location"
import debug from "./debug"
async function fetch(endpoint, { body, ...customConfig } = {}) {
  const headers = { "Content-Type": "application/json" }
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }
  debug("fetch url: %s , config: %o", endpoint, JSON.stringify(config, null, 2))
  return fetchFn(endpoint, config).then(async response => {
    const dataText = await response.text()
    let data
    try {
      data = JSON.parse(dataText)
    } catch (error) {
      // do nothing
    }
    if (response.ok) {
      if (data) {
        debug(
          "fetch response: %s , data: %o",
          endpoint,
          JSON.stringify(data, null, 2)
        )
        return data
      } else {
        debug("fetch response: %s , data: %o", endpoint, dataText)
        return dataText
      }
    } else {
      const message = data.message || response.statusText
      throw new ResponseError(message, {
        status: response.status,
        statusText: response.statusText,
        data: data,
      })
    }
  })
}
function getApiFecher(fetchOptions = {}) {
  return async function fetchApi(path, options = {}) {
    const raw = options.raw
    delete options.raw
    const autoLogin = options.autoLogin || false
    delete options.autoLogin
    const shouldUseAuth =
      options.shouldUseAuth !== undefined
        ? options.shouldUseAuth
        : fetchOptions.shouldUseAuth !== undefined
        ? fetchOptions.shouldUseAuth
        : true
    try {
      const finalUrl = `${apiEndpoint}${path}`
      if (shouldUseAuth !== false) {
        const token = await auth0.getToken()
        options.headers = Object.assign({}, options.headers, {
          Authorization: `Bearer ${token}`,
        })
      }
      // TODO add raw param , support dump raw data

      const data = await fetch(finalUrl, options)

      return raw ? data : toCamel(data)
    } catch (error) {
      if (error.error === "login_required") {
        if (autoLogin) {
          const appState = { targetUrl: withSearch(getPathname()) }
          debug("appState: %o", appState)
          return await auth0.loginWithRedirect({ appState })
        } else {
          throw new LoginRequired(error)
        }
      }
      if (error.data) {
        error.data = raw ? error.data : toCamel(error.data)
      }
      throw error
    }
  }
}
function fetchApi(path, options = {}) {
  return getApiFecher()(path, options)
}
async function fetchWithGithub(url, options = {}) {
  const result = await fetch(`${githubApiEndpoint}${url}`, options)
  if (result.errors) {
    throw new Error(result.errors[0].message)
  } else {
    return result
  }
}
async function fetchWithAuth1(path, options = {}) {
  const raw = options.raw
  delete options.raw
  try {
    const finalUrl = `${auth1ApiEndpoint}${path}`
    if (options.token) {
      let token = options.token
      delete options.token
      options.headers = Object.assign({}, options.headers, {
        Authorization: `Bearer ${token}`,
      })
    }

    const data = await fetch(finalUrl, options)
    return raw ? data : toCamel(data)
  } catch (error) {
    if (error.data) {
      error.data = raw ? error.data : toCamel(error.data)
    }
    throw error
  }
}
export { fetch, fetchApi, getApiFecher, fetchWithAuth1, fetchWithGithub }
export default fetch
