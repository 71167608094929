import { thunk, action } from "easy-peasy"
import { fetchApi } from "../../utils"
const sitesModel = {
  entities: {},
  states: {},
  fetchSite: thunk(async (actions, { siteId }) => {
    actions.setState({
      id: siteId,
      loading: true,
    })
    try {
      const { data } = await fetchApi(`/sites/${siteId}`, { raw: true })
      actions.setSite(data)
      actions.setState({
        id: siteId,
        loading: false,
      })
    } catch (error) {
      actions.setState({
        id: siteId,
        loading: false,
        error: error.message,
      })
    }
  }),
  addSiteSource: action((state, payload) => {
    if (state.entities[payload.siteId]) {
      const entity = state.entities[payload.siteId]
      if (!entity.sources) {
        entity.sources = []
      }
      delete payload.siteId
      entity.sources.unshift(payload)
    }
  }),
  setSiteSource: action((state, payload) => {
    if (state.entities[payload.siteId]) {
      const entity = state.entities[payload.siteId]
      if (!entity.sources) {
        entity.sources = []
      }
      delete payload.siteId
      entity.sources = entity.sources.map(source => {
        if (source.source_id === payload.source_id) {
          source = {
            ...source,
            ...payload,
          }
        }
        return source
      })
    }
  }),
  setState: action((state, payload) => {
    if (!state.states[payload.id]) {
      state.states[payload.id] = {}
    }
    state.states[payload.id] = { ...state.states[payload.id], ...payload }
  }),
  setSite: action((state, payload) => {
    state.entities[payload.site_id] = payload
  }),
}
export default sitesModel
