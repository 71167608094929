import React, { useState } from "react"
import { grommet, defaultProps, base as baseTheme } from "grommet"
import merge from "lodash.merge"
import { ThemeContext } from "../../contexts"
export const ThemeProvider = props => {
  const [themeMode, setThemeMode] = useState("light")
  const theme = merge({}, defaultProps.theme, baseTheme, grommet)
  const themeContextValue = {
    theme,
    themeMode,
    setThemeMode,
  }
  return (
    <ThemeContext.Provider value={themeContextValue}>
      {props.children}
    </ThemeContext.Provider>
  )
}
