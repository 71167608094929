export class ResponseError extends Error {
  constructor(message, { status, statusText, data }) {
    super(message)
    this.name = "ResponseError"
    this.status = status
    this.statusText = statusText
    this.data = data
  }
}

export class LoginRequired extends Error {
  constructor(error) {
    super(error.error_description)
    this.name = "LoginRequired"
    this.data = {
      state: error.state,
    }
  }
}
