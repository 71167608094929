import { Auth0Client } from "@auth0/auth0-spa-js"
import debug from "./debug"
import {
  auth0Domain,
  auth0Audience,
  auth0ClientId,
  auth0RedirectURI,
  auth0Scope,
} from "../configs"

class Auth0 {
  constructor(props, options = {}) {
    if (typeof window === "undefined") {
      // only use at client, not support ssr
      return
    }
    this.client = new Auth0Client(props)
    debug("init auth0 client: %o", props)
    this.options = options
    this.inited = false
    this._initCallbacks = []
    this._callbacks = []
  }
  _hanldeCallback(error, data) {
    // trigger callbacks
    this._callbacks.forEach(fn => {
      fn(error, data)
    })
    this._callbacks = []

    if (error && error.error === "login_required") {
      // trigger callbacks
      this._initCallbacks.forEach(fn => {
        fn(null)
      })
    } else {
      // trigger callbacks
      this._initCallbacks.forEach(fn => {
        fn(error, data)
      })
    }
    this._initCallbacks = []
  }
  async init() {
    return new Promise(async (resolve, reject) => {
      if (!this.inited) {
        // callback
        this._initCallbacks.push(err => {
          if (err) {
            reject(err)
          } else {
            resolve()
          }
        })
        try {
          const data = await this.client.getTokenSilently()
          this.inited = true
          this._hanldeCallback(null, data)
        } catch (error) {
          debug("auth error: %o", error)
          this.inited = true
          this._hanldeCallback(error)
        }
      } else {
        return resolve()
      }
    })
  }
  loginWithRedirect(params) {
    return new Promise((resolve, reject) => {
      if (!this.inited) {
        if (this.options.autoInit === false) {
          this.options.autoInit = true
          this.init()
        }
        // callback
        this._callbacks.push(err => {
          if (err) {
            reject(err)
          } else {
            this.client.loginWithRedirect(params).then(resolve).catch(reject)
          }
        })
      } else {
        this.client.loginWithRedirect(params).then(resolve).catch(reject)
      }
    })
  }
  getToken() {
    return new Promise((resolve, reject) => {
      if (!this.inited) {
        if (this.options.autoInit === false) {
          this.options.autoInit = true
          this.init()
        }
        // callback
        this._callbacks.push((err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      } else {
        this.client.getTokenSilently().then(resolve).catch(reject)
      }
    })
  }
}
export const auth0 = new Auth0({
  domain: auth0Domain,
  client_id: auth0ClientId,
  audience: auth0Audience,
  redirect_uri: auth0RedirectURI,
  scope: auth0Scope,
})
