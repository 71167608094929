import { useContext } from "react"
import useFetch from "swr"
import { ThemeContext, Auth0Context, LocationContext } from "../contexts"
export { useIntl } from "../utils"
export const useTheme = () => {
  const themeContext = useContext(ThemeContext)
  return themeContext
}
export const useAuth0 = () => useContext(Auth0Context)
export const useLocation = () => useContext(LocationContext)
export const useApi = useFetch
